<template>
  <div></div>
</template>

<script>
import FlightsMixins from '@/modules/flights/mixins/FlightsMixins';
export default {
  name: 'RootPage',
  data() {
    return {
      ldClientNotLoaded: null,
      enableFlightsLanding: false,
    };
  },
  mixins: [FlightsMixins],
  beforeRouteEnter(to, from, next) {
    next(async (vm) => {
      if (!!vm.$ldclient && !!vm.$ldclient.ldclient) {
        vm.ldClientNotLoaded = false;
        await vm.checkFlightLandingFF();
      } else {
        vm.ldClientNotLoaded = true;
        vm.$store.dispatch('initLaunchDarkly', {
          launchdarkly_client_id: vm.ldClientId,
          vm,
        });
      }
    });
  },
  watch: {
    ['$ldclient.ready']: {
      immediate: true,
      handler(val) {
        if (!!val && !!this.ldClientNotLoaded) {
          this.checkFlightLandingFF();
        }
      },
    },
  },
  methods: {
    async checkFlightLandingFF() {
      this.enableFlightsLanding = await this.$ldclient.variation(
        'flights-landing-page',
        true,
        true
      );
      this.$ldclient.$on('change:flights-landing-page', (value) => {
        this.enableFlightsLanding = value;
      });
      if (this.enableFlightsLanding) {
        this.$router.push({
          name: 'flights',
          query: {
            ...this.$route.query,
          },
        });
      } else {
        this.$router.push({
          name: 'concierge',
          query: {
            ...this.$route.query,
          },
        });
      }
    },
  },

  computed: {
    ldClientId() {
      return this.$store.state.configs.launchdarkly_client_id;
    },
  },
};
</script>

<style scoped></style>
